@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "./scss/custom";
@import "~bootstrap/scss/bootstrap";
@import "./scss/main.scss";
@import "./scss/blog.scss";
@import "./scss/team-members.scss";
@import "./scss/header.scss";
@import "./scss/testimonials-slider.scss";
@import "./scss/contact-form.scss";
@import "./scss/footer.scss";


// NO RESULTS PAGE


body{
    background-color: $reg-card;

    .wrapper{
        background-color: white;
        max-width: 1276px;
        margin: 0 auto;
    }

}

.assoc-intro{
    
    p{
        letter-spacing: .8px;
    }
}

.assoc{
    margin-bottom: 120px;

    @include media-breakpoint-down(md){
        margin-bottom: 80px;
    }

    p{
        letter-spacing: .8px;
        line-height: 21px;
    }

    .assoc-img{

        @include media-breakpoint-down(lg){
            margin-bottom: 40px;
        }
        img{
            filter: grayscale(100%) opacity(.8);
            transition: .4s all;

            @include media-breakpoint-down(md){
                width: 100%;
            }

            &:hover{
                filter: none;
            }
        }
    }
}

.no-results {
    margin: 40px 0;
    p{
        font-size: 30px;
    }
}

html {
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.vid-overlay{
    background-position: center !important;
    z-index: 11;
    background-size: cover !important;
    height: 645px;
    // opacity: 0.3;
    position: absolute;
    left: -8px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    width: 1148px;

    @include media-breakpoint-down(xl){
        width: 88.4%;
    }

    @include media-breakpoint-down(lg) {
        height: 444px;
        left: 0;
        width: 96%;
    }

    @include media-breakpoint-down(md){
        height: 344px;
        left: 0;
        width: 94.5%;
    }
    .play-circle{
        width: 184px;
        background: white;
        height: 180px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
        cursor: pointer;

        .play {
            box-sizing: border-box;
            width: 74px;
            height: 74px;
            border-width: 37px 0px 37px 74px;
            border-color: transparent transparent transparent black;
            border-style: solid;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            right: 0;
            margin: auto;
        }
    }   
    
}