.page-content{

    div.t-member-wrapper{
        margin: 60px 0px;
  
        h4{
          margin-bottom: 7px !important;
        }
  
        .t-member{
          margin-bottom: 44px;
  
          @include media-breakpoint-down(md){
            margin-bottom: 24px;
          }
        }
      }
    
      div.sp-wrapper{
  
        div.sp-notice {
          border: 2px solid $reg-main;
          border-radius: 100%;
          height: 280px;
          width: 284px;
          display: flex;
          align-items: center;
          padding: 20px 7px;
          margin: 0 auto;
      
          img.sp-img{
            width: 264px;
            height: 260px;
            border-radius: 100%;
            margin-left: 1px;
          }
        }
    
        .sp-text{
          font-family: $roboto;
          font-size: 14px;
          letter-spacing: .35px;
          text-align: center;
          max-width: 280px;
          margin: 20px auto 60px auto;
          line-height: 19px;
    
          strong{
            font-family: $roboto;
            letter-spacing: .3px;
          }
        }
    }

    div.team{

        .t-member-wrapper {
            margin: 0;

            h4{
                margin-bottom: 10px !important;
            }

            > div{
                background: $reg-card;
                border-radius: 20px;
                padding: 40px 20px 0px 20px;
                margin-bottom: 20px;

                &:last-of-type{
                    margin-bottom: 60px;
                }

            }

            .t-member{
                margin-bottom: 34px;

                .sp-notice{
                    @include media-breakpoint-down(md){
                        height: 240px;
                        width: 244px;
                    }

                    .sp-img{
                        @include media-breakpoint-down(md){
                            width: 224px;
                            height: 220px;
                        }
                    }
                }
            }

            .bio{
                p{
                letter-spacing: .4px;
                }
            }
        }
    }
}