.f-associations{
  background: white;
  min-height: 240px;
  padding: 0 34px;

  @include media-breakpoint-down(md){
    padding: 0 20px;
  }

  .assoc{
    width: max-content;

    @include media-breakpoint-down(lg){
      width: 33%;
    }

    @include media-breakpoint-down(md){
      width: 50%;
    }

    @include media-breakpoint-down(sm){
      width: 180px;
    }

    .assoc-img{
      width: max-content;

      @include media-breakpoint-down(md){
        margin-bottom: 60px;
        width: inherit;
      }

      img{
        height: 100px;
        width: auto;

        @include media-breakpoint-down(md){
          height: 100%;
        };
      }
    }
  }
}

.main-footer {
  background-color: $blue;
  color: white;
  font-family: $roboto;
  font-weight: 400;
  min-height: 200px;
  padding: 0 60px;

  .address {

    @include media-breakpoint-down(md){
      margin: 0 auto;
      margin-bottom: 30px;
      padding: 0;
    }

    p {
      line-height: 1.42em !important;
      font-size: 14px;
      letter-spacing: 0.36px;
      padding: 0;

      @include media-breakpoint-down(md){
        min-width: 200px;
      }
    }

    a {
      color: white;
      text-decoration: none;
      transition: 0.2s all;

      &.gm-address{
        text-decoration: underline;
      }

      &:hover {
        color: $reg-callout;
      }
    }
  }

  .office-hours {

    @include media-breakpoint-down(md){
      margin: 0 auto;
      margin-bottom: 30px;
      padding: 0;
    }

    p {
      font-size: 14px;
      line-height: 1.4em !important;
      padding: 0;
      letter-spacing: 0.35px;

      @include media-breakpoint-down(md){
        min-width: 200px;
        width: 100%;
      }
    }
  }

  .copyright {
    padding-left: 22px;

    @include media-breakpoint-down(md){
      padding-left: 0px;
    };

    small {
      font-size: 10px;
      letter-spacing: 0.25px;

      a{
        color: white;
        text-decoration: none;
      }
    }
  }

  .social {

    @include media-breakpoint-down(md){
      margin-bottom: 30px;
    }

    ul {
      display: flex;
      align-items: center;

      li {
        list-style: none;
      }
    }
    i {
      font-size: 20px;
      margin: 0 15px;
      color: white;
      transition: 0.2s all;

      &.icon-linkedin {
        font-size: 22px;
        margin-left: 16px;
      }

      &.icon-facebook {
        font-size: 21px;
        margin: 0px 10px;
      }

      &:hover {
        color: $reg-callout;
      }
    }
  }
}
