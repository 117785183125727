
body{

  div.page-media{
    margin-bottom: 60px;
    margin-top: 8px;

    iframe{
      border-radius: 20px;
      width: 99.2%;
      height: 645px;

      @include media-breakpoint-down(lg){
        width: 100%; 
        height: 445px;
      }

      @include media-breakpoint-down(md){
        height: 345px;
      }
    }
  }
  
  .page-content {
    padding: 60px 60px 0px 60px;

    @include media-breakpoint-down(lg){
      padding: 60px 20px 0px 20px;
    }

    ~ .page-content{
      padding: 0px 60px 0px 60px;

      @include media-breakpoint-down(lg){
        padding: 0px 20px 0px 20px;
      }
    }

    .question{
      color: $reg-headline;
      font-family: $roboto-slab;
      font-size: 24px;
      background: rgba(209,209,212, 0.3);
      margin-bottom: 20px;
      padding: 26px 24px;
      border-radius: 19px;
      transition: .2s all;

      &:hover{
        background: rgb(26, 151, 169, 0.3);
      }

      &:last-of-type{
        margin-bottom: 36px;
      }
    }
  
    .side-col {
      padding-left: 14px;

      @include media-breakpoint-down(md){
        padding-left: 0px;
      }
    }
  
    .three-col {
      margin-bottom: 60px;
  
     
      a.f-treatment{
        text-decoration: none;
        width: 100%;
        min-height: 250px;
        transition: .4s all;
  
        &:hover{
          background-color: $reg-input;
        }
  
        h3{
          letter-spacing: 0.18px;

          @include media-breakpoint-down(lg){
            font-size: 22px;
          }
        }
      }
  
      .post-content {
        background-color: $reg-card;
        padding: 26px 24px 28px 26px;
        max-width: 371px;
        border-radius: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-down(xxl){
        max-width: 32.1%;
        }

        @include media-breakpoint-down(lg){
          max-width: 48.5%;
        }

        @include media-breakpoint-down(md){
          max-width: 371px;
        }

        @include media-breakpoint-down(sm){
          max-width: 100%;
        }


      }
  
      small.ol {
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        line-height: 14px;
      }
      p {
        font-size: 14px;
        letter-spacing: 0.35px;
        line-height: 19px;
        margin-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: -4px;
      }
    }
  
    article {
      margin-bottom: 60px;
      color: black;

      &.lsp{
        letter-spacing: .8px;
      }
  
      h3 {
        margin-bottom: 1.24rem !important;
      }
  
      blockquote {
        text-align: center;
        margin: 60px auto 0px auto;
        letter-spacing: 1.2px;
        font-size: 24px;
        color: $reg-headline;
        max-width: 531px;
        line-height: 32px;

        @include media-breakpoint-down(md){
          font-size: 20px;
          padding: 0 30px;
        }
      }
  
      &.page-content-body {

        figure {
          img {
            max-width: 100%;
          }
        }
  
        .page-tags{
          margin-top: -7px;
  
          a{
            background: $reg-grey;
            color: white;
            border-radius: 20px;
            margin-right: 10px;
            letter-spacing: 1.5px;
            padding: 4px 16px;
            font-size: 10px;
            text-decoration: none;
            text-transform: uppercase;
            transition: .5s all;
  
            &:hover{
              background: $reg-main;
            }
          }
        }
  
  
        h5 + p {
          margin-left: 30px;
        }
  
        h6 {
          margin-left: 30px;
  
          + ul,
          + ol {
            margin-bottom: 1.5rem;
  
            li {
              max-width: 440px;
              line-height: 21px;
              margin: 17px 31px;
              letter-spacing: 0.8px;
            }
          }
  
          + ul {
            list-style: none;
  
            li:before {
              content: "•";
              font-size: 36px;
              color: $reg-headline;
              font-weight: bold;
              display: inline-block;
              width: 0.55em;
              height: 0px;
              margin-left: -0.55em;
              vertical-align: text-top;
            }
          }
  
          + ol {
            li::marker {
              color: $reg-headline;
            }
          }
        }
      }

      &.review{
        border-radius: 20px;
        margin-bottom: 20px;

        &:first-of-type{
          margin-top: 60px;
        }

        .page-media{
          margin-top: 60px;

          .vid-overlay{
            width: 756px;
            right: 390px;
            height: 428px;

            .play {
              box-sizing: border-box;
              width: 74px;
              height: 74px;
              border-width: 37px 0px 37px 74px;
              border-color: transparent transparent transparent #202020;
            }


            @include media-breakpoint-down(xl){
              width: 52%;
              right: 38%;
            }

            @include media-breakpoint-down(lg){
              width: 48%;
              right: 38%;
              margin-left: 20px;
            }

            @include media-breakpoint-down(md){
              width: 95%;
              right: 0;
              margin-left: auto;
              height: 344px;
            }
          }

          iframe{
            height: 428px;

            @include media-breakpoint-down(md){
              height: 344px;
            }
          }
        }

        blockquote{

          &:not(first-of-type){
            margin-top: 0px;
          }

          &.r-normal{
            max-width: 100%;
            border-radius: 20px;
            padding: 22px 40px 20px 20px;
            line-height: 21px;
            font-size: 16px;
            letter-spacing: .8px;
            text-align: left;
            background: $reg-card;
            color: black;
          }

          &.r-quote{
            margin: 60px auto;
          }

        }
      }
    }
  
    div.callout {
      border-radius: 24px;
      background: $reg-callout-35;
      max-width: 764px;
      padding: 26px 22px 8px 44px;
      margin-bottom: 60px;
  
      p {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: .9px;
          line-height: 30px;
  
          span{
              color: $reg-headline;
          }
      }
    }
    
  
    div#quick-links {
      margin-bottom: 64px;

      ul {
        list-style: none;
        padding: 0;
        padding: 30px 20px 30px 42px;
        border-radius: 24px;
        background: $reg-callout-35;
  
        li {
          max-width: 320px;
          line-height: 22px;

          @include media-breakpoint-down(md){
            max-width: 100%;
          }
          a {
            color: black;
            text-decoration: none;
            letter-spacing: 0.8px;
            font-family: $roboto;
            font-size: 16px;
            transition: 0.2s all;
  
            &:hover {
              color: $reg-headline;
            }
          }
  
          &.quick-links-heading {
            h6 {
              font-weight: 600;
              color: $reg-bodyheadline;
            }
  
            margin-bottom: 14px;
            text-transform: uppercase;
            font-family: $roboto-slab;
            padding-top: 1px;
            letter-spacing: 0.7px;
            font-weight: 600;
            font-size: 16px;
        
  
            &:before {
              display: none;
            }
          }
  
          &:before {
            content: "\2022";
            font-size: 36px;
            color: $reg-headline;
            font-weight: bold;
            display: inline-block;
            width: 0.48em;
            height: 0px;
            margin-left: -0.6em;
            vertical-align: top;
          }
        }
      }
    }

    .office-hours,
    .address{
      margin-bottom: 60px;

      a{
        transition: .2s all;

        &:hover{
          color: $reg-btn;
        }
      }

      @include media-breakpoint-down(lg){
        margin-bottom: 30px;
      }
      
      p{
        font-size: 18px;
        letter-spacing: .6px;
        line-height: 28px;

        @include media-breakpoint-down(xl){
          font-size: 20px;
          line-height: 26px;
        }

        @include media-breakpoint-down(md){
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }  

  a.btn-sm{
    font-size: 10px;
    width: max-content;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 20px;
    background: $reg-callout;
    color: white;
    margin-right: 14px;
    padding: 4px 12px;
    margin-top: -2px;
    transition: .2s all;

    @include media-breakpoint-down(md){
      margin-right: 0px;
    }

    &:hover{
      background-color: $reg-btn;
    }
  }
}

