//Blog Index

.blogIndex {
  #blogPosts {
    @include media-breakpoint-down(md) {
      order: 2;
    }

    .blog-post {
      background-color: $reg-card;
      max-width: 764px;
      border-radius: 24px;
      text-decoration: none;
      padding: 26px 14px 2px 27px;
      margin-bottom: 18px;
      transition: 0.2s all;

      h3 {
        word-wrap: break-word;
      }

      span {
        font-size: 10px;
        letter-spacing: 1.4px;
        font-weight: 600;
        margin-bottom: 17px;
      }

      p {
        letter-spacing: 0.8px;
      }

      &:hover {
        background-color: $reg-input;
      }
    }
  }

  #blogTags {

    .filter-articles {
      width: 100%;
      display: flex;
      font-family: $roboto-slab;
      margin-top: -38px;
      margin-bottom: 16px;

      @include media-breakpoint-down(md){
        margin-bottom: 0;
        align-items: center;
        flex-direction: column;
      }

      p:first-of-type {
        letter-spacing: 1.4px;
        margin: 0;
        font-weight: 600;
        color: $reg-bodyheadline;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }

      .clear-filters {
        color: $reg-callout;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          margin-bottom: 16px;
        }

        #clearFilters {
          color: $reg-callout;
          background: transparent;
          padding: 0;
          border: none;
          text-decoration: underline;
        }  

      }
    }
    
    .tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      height: max-content;
      margin-bottom: 18px;

      @include media-breakpoint-down(md) {
        order: 1;
        justify-content: center;
      }

      .tag-filter {
        background: $reg-grey;
        border: none;
        font-size: 10px;
        width: max-content;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 20px;
        color: #fff;
        margin: 0px 10px 10px 0px;
        padding: 4px 12px;
        transition: all 0.2s;
        height: max-content;

        &:disabled {
          background: #d9d9d9;
        }

        &:hover:not(:disabled) {
          background: $reg-callout;
        }

        &.active {
          background: $reg-btn;

          &:hover:not(:disabled) {
            background: $reg-callout;
          }
        }

        @include media-breakpoint-down(lg) {

          &:hover:not(:disabled) {
            background: $reg-grey;
          }

          &.active {
            &:hover:not(:disabled) {
              background: $reg-btn;
            }
          }
        }
      }
    }
  }
}

// Blog Entry
.blog {
  .blog-tags {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: 26px 0 16px 0;

    a.tag {
      background: $reg-grey;
      border: none;
      font-size: 10px;
      width: max-content;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      text-decoration: none;
      border-radius: 20px;
      color: #fff;
      margin: 0px 10px 10px 0px;
      padding: 6px 12px;
      transition: all 0.2s;
      height: max-content;

      &:hover {
        background: $reg-callout;
      }

      @include media-breakpoint-down(md) {
        &:hover {
          background: $reg-grey;
        }
      }
    }
  }
}
